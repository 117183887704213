import React, {useContext, useEffect, useState} from 'react';
import {Context} from '../../AppContext';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import Preview from '../../Components/SlatePreview';
import Image from '../../Components/Image';
import styled from 'styled-components';
const appConfig = require('../../data.json');
const queryString = require('query-string');

export default function BlogDetail({location}) {
  const app = useContext(Context);
  const parsed = queryString.parse(location.search);
  const {id} = parsed;
  const [record, setRecord] = useState();
  const {services} = app.state;

  useEffect(() => {
    (async () => {
      if (services) {
        app.actions.setLoading(true);
        try {
          let resp = await app.actions.getBlog({
            query: {
              id,
            },
          });
          setRecord(resp);
        } catch (err) {
          console.log(err);
        }
        app.actions.setLoading(false);
      }
    })();
  }, [app.actions, services, id]);

  if (!record) {
    return <ContentWrapper></ContentWrapper>;
  }

  return (
    <ContentWrapper>
      <Image src={record.image} style={{width: '100%'}} />
      <Wrapper>
        <Title>{record.title}</Title>
        <Preview content={record.content} readOnly />
      </Wrapper>
    </ContentWrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  /* font-size: ${appConfig.fontSize.md}px; */
`;

const Title = styled.h2`
  font-size: ${appConfig.headingSize.lg}px;
  font-weight: 400;
  margin-bottom: 30px;
`;
